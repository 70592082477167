import React from 'react';
import { useState, useEffect } from 'react';
import { getCategories, getNavLinks } from '../../actions';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';

const Menu = () => {
  const [allCategories, setAllCategories] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [menu, setMenu] = useState(false);
  const [navbar, setNavBar] = useState([]);
  const [hover, setHover] = useState();
  const [visible, setVisible] = useState(false);
  const [children, setChildren] = useState([]);

  const [visibleNav, setVisibleNav] = useState([]);
  const [navChildren, setNavChildren] = useState([]);

  useEffect(() => {
    const loadAllCategories = async () => {
      await getCategories()
        .then((response) => {
          setAllCategories(response.documents);
          setParentCategories(
            response.documents.filter(
              (category) => category.parent === 'isParent'
            )
          );
          getNavLinks()
            .then((response) => {
              setNavBar(response.documents);
              var arr = response.documents;

              setVisibleNav(
                arr.map((a) => {
                  return { id: a.$id, name: a.name, visible: false };
                })
              );
            })
            .catch((error) => console.error(error.message));
        })
        .catch((e) => console.error());
    };

    loadAllCategories();
  }, []);

  useEffect(() => {
    const arr = getChildCategories(hover);
    setChildren(arr);
  }, [hover]);

  useEffect(() => {
    const item = visibleNav.find((i) => i.visible === true);
    if (!item) return;
    const arr = getChildNavCategories(item?.name);
    setNavChildren(arr);
  }, [visibleNav]);

  function getChildNavCategories(navName) {
    const ele = navbar.find((n) => n.name === navName);
    return ele?.categories;
  }

  function getChildCategories(parentName) {
    const ele = parentCategories.find((c) => c.name === parentName);

    return allCategories.filter((c) => c?.parent === ele?.$id);
  }


  return (
    // Navbar Menu
    <div className='hidden  lg:flex w-full bg-white shadow-xl'>
      <nav className='px-8 flex w-full justify-between md:px-12 '>
        <ul className='flex items-center space-x-7'>
          <li className='underlined-animated group relative  border-b-0 border-transparent hover:border-primary'>
            <button
              onClick={() => setMenu(!menu)}
              className=' flex items-center mr-1 h-full py-[10px]'
            >
              <span className='lg:text-lg font-semibold'>All Categories</span>
              <ArrowDropDownIcon
                className='transform transition-transform duration-300 group-hover:rotate-180 flex text-xs text-primary-color text-primary'
              />
            </button>

            <div
              onMouseEnter={(e) => setVisible(true)}
              onMouseLeave={(e) => setVisible(false)}
              className={
                visible
                  ? 'my-2 bg-gray-100 absolute w-80 bottom-0 translate-y-full z-20 shadow-lg'
                  : ' my-2  hidden group-hover:block absolute w-80 bg-gray-100  bottom-0 translate-y-full z-20 shadow-lg'
              }
            >
              <div className='relative group'>
                {children.length > 0 ? (
                  <div className='absolute hidden group-hover:block top-0 right-0 bg-gray-100 translate-x-full py-3 w-full shadow-md'>
                    <ul>
                      {children.map((arr, i) => {
                        return (
                          <li className='py-2 cursor-pointer px-4 hover:bg-gray-200'>
                            <a
                              className='flex w-full lg:text-lg'
                              href={`/category/${arr.$id}`}
                            >
                              {arr.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : null}
                <ul className='w-full py-[10px]'>
                  {parentCategories.map((p, idx) => (
                    <>
                      <li
                        onMouseEnter={(e) => setHover(e.target.innerText)}
                        onMouseLeave={(e) => setHover(e.target.innerText)}
                        key={idx}
                        className={
                          hover === p.name
                            ? 'py-2 px-4 group w-full bg-gray-200'
                            : 'py-2 px-4 group w-full'
                        }
                      >
                        <a
                          className='w-full flex lg:text-lg '
                          href={`/category/${p.$id}`}
                        >
                          {p.name}
                        </a>
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            </div>
          </li>
          <li className='underlined-animated border-transparent hover:border-primary'>
            <a href='/'>
              <button className=' flex items-center mr-1 h-full py-[10px] '>
                <span className='lg:text-lg font-semibold'>Home</span>
              </button>
            </a>
          </li>
          {navbar &&
            navbar.map((nav, idx) => (
              <li
                onMouseEnter={(e) =>
                  setVisibleNav(
                    visibleNav.map((item) =>
                      item.id === nav.$id
                        ? { ...item, visible: true }
                        : { ...item, visible: false }
                    )
                  )
                }
                onMouseLeave={(e) =>
                  setVisibleNav(
                    visibleNav.map((item) => {
                      return { ...item, visible: false };
                    })
                  )
                }
                className='bg-white group underlined-animated  border-transparent hover:border-primary relative h-full flex items-center '
              >
                <a href={`/group/${nav.$id}`}>
                  <button className=' flex group items-center mr-1 h-full  py-[10px]  font-semibold'>
                    <span className='lg:text-lg font-semibold'>
                      {nav?.name}
                    </span>
                    <ArrowDropDownIcon
                      className='transform transition-transform duration-300 group-hover:rotate-180 flex text-xs text-primary-color text-primary'
                    />
                  </button>
                </a>
                <div
                  className={
                    visibleNav[idx].visible
                      ? 'my-2 bg-gray-100 absolute w-80 bottom-0 translate-y-full z-20 shadow-lg'
                      : ' my-2  hidden group-hover:block absolute w-80 bg-gray-100  bottom-0 translate-y-full z-20 shadow-lg'
                  }
                >
                  <ul className='flex flex-col my-2'>
                    {navChildren.map((arr, i) => {
                      return (
                        <li className='py-2 cursor-pointer px-4 hover:bg-gray-200'>
                          <a
                            className='flex w-full lg:text-lg '
                            href={`/category/${arr.$id}`}
                          >
                            {arr.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            ))}
        </ul>
        <ul className='flex space-x-5 text-lg items-center'>
          <li className='underlined-animated border-transparent hover:border-primary'>
            <a href='faqs.html' className='lg:text-lg font-semibold'>FAQ's</a>
          </li>
          <li className='underlined-animated border-transparent hover:border-primary'>
            <a href='/contact' className='lg:text-lg font-semibold'>Contact Us</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
