import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { getProductById } from '../../actions';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import Loading from '../../utils/Loading';
import Products from '../../Components/Dashboard/Products/Products';
import { addToCart, currentCartState, updateQty } from '../../app/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../app/persist';
import ErrorPage from '../ErrorPage/ErrorPage';
import { addToWishlist } from '../../app/wishlistSlice';
import { BiPurchaseTag } from 'react-icons/bi';
import ProductImageSlider from '../../Components/Dashboard/Products/ProductImageSlider';
import { Helmet } from 'react-helmet';

import toast, { Toaster } from 'react-hot-toast';

const ProductPage = ({ products }) => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [exist, setExist] = useState(true);
  const dispatch = useDispatch();
  const currentCart = useSelector(currentCartState);
  const [addQtyValue, setAddQtyValue] = useState(1);
  const navigate = useNavigate();

  const fetchProductById = async () => {
    getProductById(productId)
      .then((res) => {
        if (res.status) {
          setProduct(res.res);
        } else {
          setExist(false);
        }
      })
      .catch((e) => {
        console.error(e.message);
      });
  };

  function getRelevantProducts() {
    let relevantProducts = [];
    for (let prod of products) {
      if (
        prod?.category?.$id === product?.category?.$id &&
        prod?.$id !== productId
      ) {
        relevantProducts.push(prod);
      }
    }
    setRelatedProducts(relevantProducts);
  }

  useEffect(() => {
    fetchProductById();
  }, [productId]);

  useEffect(() => {
    getRelevantProducts();
  }, [product]);

  function trimCharacters(str) {
    return str.substring(3, str.length - 4);
  }

  const addProductToCart = async (product) => {
    let flag = null;
    for (let prod of currentCart) {
      if (prod.productId === product.$id) {
        flag = prod;
        break;
      }
    }

    if (
      flag &&
      parseInt(flag.qty) + parseInt(addQtyValue) <= product.quantity
    ) {
      dispatch(
        updateQty({
          productId: flag.productId,
          qty: parseInt(flag.qty) + parseInt(addQtyValue),
        })
      );
      toast.success('Cart updated');

      navigate('/cart');
    } else if (
      flag &&
      parseInt(flag.qty) + parseInt(addQtyValue) > product.quantity
    ) {
      toast.error(`Max available units: ${product.quantity}`);
    } else {
      dispatch(
        addToCart({ productId: product.$id, product, qty: addQtyValue })
      );
      toast.success('Added to cart');

      navigate('/cart');
    }
  };

  const addProductToWishlist = async (product) => {
    dispatch(addToWishlist(product));
    toast.success('Added to wishlist');
  };

  if (!product && !exist) {
    return <ErrorPage />;
  } else if (!product) {
    return <Loading />;
  }

  return (
    <div className='flex flex-col w-full'>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='theme-color' content='#FFFFFF' />
        <meta name='description' content={product?.description} />

        <link rel='manifest' href='%PUBLIC_URL%/manifest.json' />

        <title>{product?.title} | TechSouqDubai</title>

        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
        <link
          href='https://fonts.googleapis.com/css2?family=Audiowide&family=Poppins:ital,wght@0,500;1,600&display=swap'
          rel='stylesheet'
        />
      </Helmet>
      <Navbar />

      <div className='bg-gray-100 px-2 sm:px-8'>
        <div className='grid grid-cols-12 gap-5 my-8 mx-4 rounded-lg bg-white shadow-xl p-6 xs:p-8'>
          <div className='col-span-12 max-h-[500px] md:col-span-6'>
            {/* <div className='w-full justify-center flex h-full p-4'>
                    <img src={`${product.images[0]}`} alt='product' className='w-full object-contain h-full' />
                  </div> */}

            <ProductImageSlider images={product.images} />
          </div>
          <div className='col-span-12 md:col-span-6'>
            <div className='my-1 flex justify-between items-center'>
              <h1 className='transition-all-300 break-normal text-4xl font-medium hover:text-primary'>
                {product.title}
              </h1>
              {product.quantity > 0 ? (
                <span className='rounded-md bg-green-300 px-2 py-1 text-xs font-bold uppercase text-white'>
                  instock
                </span>
              ) : (
                <span className='rounded-md bg-red-300 px-2 py-1 text-xs font-bold uppercase text-white'>
                  out of stock
                </span>
              )}
            </div>
            <div className='product-val-stock my-2 flex justify-between'>
              <div className='ml-auto'></div>
            </div>
            {!product.contactForPrice ? (
              product.quantity > 0 ? (
                <div className='my-5 flex items-center gap-5'>
                  <div className='flex rounded-lg bg-white px-3 py-2 text-primary-color shadow'>
                    <span className='text-sm text-primary'>AED</span>
                    <span className='text-2xl text-primary font-semibold leading-7'>
                      {product.salePrice}
                    </span>
                  </div>
                </div>
              ) : (
                <button
                  className='my-5 flex items-center gap-5'
                  onClick={() => navigate('/contact', { state: { product } })}
                >
                  <div className='flex rounded-lg bg-gray-100 px-3 py-2 shadow-lg hover:scale-105 cursor-pointer hover:transition-all hover:duration-200'>
                    <span className='text-2xl text-primary font-semibold leading-7'>
                      Contact for updates
                    </span>
                  </div>
                </button>
              )
            ) : (
              <button
                className='my-5 flex items-center gap-5'
                onClick={() => navigate('/contact', { state: { product } })}
              >
                <div className='flex rounded-lg bg-gray-100 px-3 py-2 shadow-lg hover:scale-105 cursor-pointer hover:transition-all hover:duration-200'>
                  <span className='text-2xl text-primary font-semibold leading-7'>
                    Contact for price
                  </span>
                </div>
              </button>
            )}
            <div className='my-4'>
              <h2 className='clamp-5  break-normal'>
                {trimCharacters(product.description)}
              </h2>
            </div>

            {product.quantity > 0 && !product.contactForPrice ? (
              <>
                <div className='flex justify-between md:justify-start items-center gap-4'>
                  <div className='inline-flex rounded-lg bg-white shadow'>
                    <select
                      className='w-16 p-2 border-none bg-transparent text-center text-lg text-gray-800 focus:border-none focus:outline-none focus:ring-0'
                      value={addQtyValue}
                      onChange={(e) => setAddQtyValue(e.target.value)}
                    >
                      {Array(product.quantity)
                        .fill('')
                        .map((qty, index) => {
                          return <option value={index + 1}>{index + 1}</option>;
                        })}
                    </select>
                  </div>
                  <button
                    className='bg-primary hidden md:flex transition-all-300 h-full items-center justify-center gap-2 rounded-lg bg-primary-color p-2'
                    type='submit'
                    onClick={() => addProductToCart(product)}
                  >
                    <AddShoppingCartOutlinedIcon className='text-white' />
                    <span className='font-bold uppercase text-white'>
                      Add to cart
                    </span>
                  </button>
                  <button
                    className='bg-primary transition-all-300 flex min-h-[40px] min-w-[40px] cursor-pointer items-center justify-center gap-2 rounded-lg bg-primary-color p-2 hover:bg-primary-hover'
                    onClick={() => addProductToWishlist(product)}
                  >
                    <FavoriteBorderOutlinedIcon className='text-white' />
                  </button>
                </div>
                <button
                  className='bg-primary transition-all-300 mt-5 h-12 flex w-full md:hidden items-center justify-center gap-2 rounded-lg bg-primary-color p-2'
                  type='submit'
                  onClick={() => addProductToCart(product)}
                >
                  <AddShoppingCartOutlinedIcon className='text-white' />
                  <span className='font-bold uppercase text-white'>
                    Add to cart
                  </span>
                </button>
              </>
            ) : (
              <div className='w-full flex h-[100px] items-center text-red-500'>
                Contact us for more details!
              </div>
            )}

            <div className='mt-5 border-t border-gray-200 pt-5'>
              <div>
                <b>Categories: </b>
                <span>
                  <a
                    href={`/category/${product.category?.$id}`}
                    className='text-zinc-500'
                  >
                    {product.category?.name}
                  </a>
                </span>
              </div>
            </div>
            <div className='mt-5 border-t border-gray-200 pt-5'>
              <div className='flex w-full flex-wrap gap-[3px]'>
                {product.tags.map((tag) => (
                  <span
                    className='flex cursor-pointer w-fit text-sm bg-primary text-white px-3 py-1 rounded-full'
                    onClick={() =>
                      navigate('/search', { state: { searchTerm: tag } })
                    }
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
            {product.deliveryTime && (
              <div className='mt-5 border-t border-gray-200 pt-5'>
                <div>
                  <b>Estimated delivery time: </b>
                  <span>{product.deliveryTime}</span>
                </div>
              </div>
            )}
          </div>
          {product.specifications?.length > 0 && (
            <div className='col-span-12'>
              <div className='liner-container mb-5 flex border-b-2 border-[rgba(119,119,119,.17)]'>
                <h1 className='mb-[-2px] inline-block border-b-2 border-primary-color pb-3 text-xl font-bold uppercase'>
                  Specifications
                </h1>
              </div>
              <div className='grid grid-cols-3 gap-5'>
                <div className='col-span-12 sm:col-span-1'>
                  {product.specifications.map((spec, idx) => {
                    spec = JSON.parse(spec);
                    return (
                      <>
                        <span>
                          <b>{spec.key}:</b> {spec.value}
                        </span>
                        <br />
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        {relatedProducts.length > 0 ? (
          <Products title={'Related Products'} products={relatedProducts} />
        ) : null}
      </div>

      <Footer />
    </div>
  );
};

export default ProductPage;
