import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import { Link, useNavigate } from 'react-router-dom';
import Heading from '../../../Widgets/Heading';
import {
  addToCart,
  currentCartState,
  existsInCart,
  updateQty,
} from '../../../app/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../app/persist';
import { addToWishlist } from '../../../app/wishlistSlice';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 800 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 800, min: 600 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

/* ########## OFFERS ########## */
const Products = ({ title, products }) => {
  const dispatch = useDispatch();
  const currentCart = useSelector(currentCartState);
  const navigate = useNavigate();

  function trimCharacters(str) {
    return str.substring(3, str.length - 4);
  }

  const addProductToCart = (product) => {
    let flag = null;
    for (let prod of currentCart) {
      if (prod.productId === product.$id) {
        flag = prod;
        break;
      }
    }

    if (flag) {
      dispatch(updateQty({ productId: flag.productId, qty: flag.qty + 1 }));
      toast.success('Cart updated');

      navigate('/cart');
    } else {
      dispatch(addToCart({ productId: product.$id, product, qty: 1 }));
      toast.success('Added to cart');

      navigate('/cart');
    }
  };

  const addProductToWishlist = (product) => {
    dispatch(addToWishlist(product));
    toast.success('Product added to wishlist');
  };

  return (
    <>
      <Heading title={title} />
      <div className='md:px-8 px-3'>
        <Carousel
          responsive={responsive}
          itemClass='w-full flex justify-center p-3'
          className='w-full'
        >
          {products?.map((prod, idx) => {
            return (
              <div
                key={prod.$id}
                className='flex relative flex-col w-full h-full'
              >
                <div className='absolute z-10 top-[10px] left-[10px]'>
                  {prod.quantity < 1 ? (
                    <span className='bg-red-600 text-xs px-[8px] py-[3px] rounded-full text-white'>
                      SOLD OUT
                    </span>
                  ) : prod.contactForPrice ? (
                    <span className='bg-yellow-500 text-xs px-[8px] py-[3px] rounded-full text-white'>
                      CONTACT FOR PRICE
                    </span>
                  ) : null}
                </div>
                {prod.quantity > 0 && !prod.contactForPrice ? (
                  <div className='absolute z-10 top-[10px] right-[10px]'>
                    <div
                      className='p-[2px] transition-all-300 flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg bg-gray-400 text-white hover:bg-primary-hover'
                      onClick={() => addProductToWishlist(prod)}
                    >
                      <FavoriteBorderIcon />
                    </div>
                    <div
                      className='p-[2px] my-2 transition-all-300 flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg bg-gray-400 text-white hover:bg-primary-hover'
                      onClick={() => addProductToCart(prod)}
                    >
                      <AddShoppingCartOutlinedIcon />
                    </div>
                  </div>
                ) : null}
                <a href={`/products/${prod.$id}`} className='h-full'>
                  <div className='card-container  transition-all-300 translateY-2 relative flex h-full flex-col  rounded-lg bg-white p-5 shadow-md hover:z-[2] hover:shadow-xl hover:shadow-primary'>
                    <div className='h-[190px] flex w-full justify-center overflow-hidden rounded-lg'>
                      <img
                        className='object-contain h-full w-full'
                        src={prod.images[0]}
                        alt={prod.title}
                      />
                    </div>
                    <div className='my-3'>
                      <h2 className='clamp break-normal text-lg md:text-xl font-medium'>
                        {prod.title}
                      </h2>
                    </div>
                    <div className='mt-1 mb-1'>
                      <h3 className='clamp-2 line-clamp-3 break-normal text-sm text-gray-400'>
                        {trimCharacters(prod.description)}
                      </h3>
                    </div>

                    {/* <div className="mt-auto">
                    <a
                      className="btn-effect transition-all-300  flex w-full items-center justify-center rounded-lg bg-primary px-3 py-4"
                      href={`/products/${prod.$id}`}
                    >
                      <span className="font-bold uppercase text-white">
                        View details
                      </span>
                    </a>
                  </div> */}
                  </div>
                </a>
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default Products;
