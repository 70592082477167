import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from './app/persist';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import persistedStore from './app/persist';
import Loading from './utils/Loading';
import { render, hydrate } from 'react-dom';
import { Toaster } from 'react-hot-toast';

const rootElement = document.getElementById('root');
const isSSR = rootElement.hasChildNodes();

const renderApp = () => (
  <StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <Toaster />
        <App />
      </PersistGate>
    </Provider>
  </StrictMode>
);

if (isSSR) {
  const root = ReactDOM.hydrateRoot(rootElement);
  root.render(renderApp());
  //ReactDOM.hydrate(renderApp(), rootElement);
} else {
  const root = ReactDOM.createRoot(rootElement);
  root.render(renderApp());
  //ReactDOM.render(renderApp(), rootElement);
}

//Done for SEO optimization
/* const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <App />
      </PersistGate>
    </Provider>
  </StrictMode>
); */
