import { Client } from 'appwrite';

const categoryCollectionID = process.env.REACT_APP_CATEGORY_COLLECTION;
const ordersCollectionID = process.env.REACT_APP_ORDER_COLLECTION;
const productsCollectionID = process.env.REACT_APP_PRODUCT_COLLECTION;
const usersCollectionID = process.env.REACT_APP_USER_COLLECTION;
const mainDatabaseID = process.env.REACT_APP_DATABASE_ID;
const navLinkCollectionID = process.env.REACT_APP_NAVLINK_COLLECTION;
const categoryRelationCollectionID =
  process.env.REACT_APP_CATEGORY_RELATION_COLLECTION;

const client = new Client();

client
  .setEndpoint('https://appwrite.techsouqdubai.com/v1')
  .setProject(process.env.REACT_APP_PROJECT_ID);

export {
  client,
  categoryCollectionID,
  ordersCollectionID,
  productsCollectionID,
  mainDatabaseID,
  usersCollectionID,
  navLinkCollectionID,
  categoryRelationCollectionID,
};
