import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAllProducts, getCategoryById } from '../../actions';
import { useState } from 'react';
import ErrorPage from '../ErrorPage/ErrorPage';
import Loading from '../../utils/Loading';
import Navbar from '../../Components/Navbar/Navbar';
import Heading from '../../Widgets/Heading';
import Footer from '../../Components/Footer/Footer';
import Products from '../../Components/Dashboard/Products/Products';

import { Helmet } from 'react-helmet';

var metaDescription = '';

const CategoryPage = ({ relations, categories, products }) => {
  const { categoryId } = useParams();
  const [category, setCategory] = useState([]);
  const [categoryExists, setCategoryExists] = useState(true);

  const getCategory = () => {
    const data = [];
    const cat = categories.find((category) => category.$id === categoryId);
    if (!cat) setCategoryExists(false);
    data.push(cat);
    const related = relations.filter(
      (relation) => relation.parent.$id === categoryId
    );
    related.map((x) => data.push(x?.child));
    const array = data.map((x) => x?.name);
    setCategory(data);
    metaDescription = array.join(', ');
  };

  useEffect(() => {
    getCategory();

    // setProducts(relevantProducts);
  }, []);

  if (!categoryExists) {
    return <ErrorPage />;
  } else if (category.length < 1) {
    return <Loading />;
  }

  return (
    <div className='flex flex-col w-full'>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='theme-color' content='#FFFFFF' />
        <meta
          name='description'
          content={`Browse through all the products under the following categories ${metaDescription}.`}
        />

        <link rel='manifest' href='%PUBLIC_URL%/manifest.json' />

        <title>{category[0]?.name} | TechSouqDubai</title>

        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
        <link
          href='https://fonts.googleapis.com/css2?family=Audiowide&family=Poppins:ital,wght@0,500;1,600&display=swap'
          rel='stylesheet'
        />
      </Helmet>
      <Navbar />
      <p className='px-3 md:px-8 text-3xl font-semibold py-3'>
        {category[0]?.name}
      </p>
      {category &&
        category.map((cat, idx) => {
          let relevantProducts = [];
          for (let prod of products) {
            if (prod.category.$id === cat.$id) {
              relevantProducts.push(prod);
            }
          }

          if (relevantProducts.length > 0)
            return <Products title={cat.name} products={relevantProducts} />;
        })}

      <Footer />
    </div>
  );
};

export default CategoryPage;
