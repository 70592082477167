import React, { useEffect, useRef, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';

const HelpMenu = ({ show, setShowHelpMenu }) => {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    from_name: '',
    from_email: '',
    message: '',
    product_title: '',
  });

  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);

    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_1_ID;
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;


    if (!formValues.from_name) {
      toast.error('Please enter your name');
    } else if (!formValues.from_email) {
      toast.error('Please enter your email');
    } else if (!formValues.message) {
      toast.error('Please enter your message');
    } else {
      await emailjs.send(serviceId, templateId, formValues, publicKey).then(
        (result) => {
          console.log(result);
          setFormValues({
            from_name: '',
            message: '',
            from_email: '',
          });
          setShowHelpMenu(false);

          toast.success('Message sent successfully');
        },
        (error) => {
          toast.error('Some error occured');
        }
      );
    }

    setLoading(false);
  };

  return (
    <>
      <div
        className={
          show
            ? 'bg-[#0b0d17]/90 p-4 rounded-md z-[999] absolute max-w-screen bottom-0 right-0 opacity-100 transition-all transform duration-500 ease-out'
            : 'bg-[#0b0d17] rounded-md z-[999] h-0 w-0 absolute max-w-screen bottom-0 right-0 opacity-0 transition-all transform duration-500 ease-out'
        }
      >
        <div className='flex justify-between'>
          <span className='text-xl text-white mr-6 mb-3'>
            Contact us for more details!
          </span>
          <ClearIcon
            className='text-white text-left cursor-pointer'
            onClick={() => setShowHelpMenu(false)}
          />
        </div>
        <form
          onSubmit={(e) => sendEmail(e)}
          ref={form}
          className='flex flex-col'
        >
          <label htmlFor='from_name' className='text-white'>
            Name
          </label>
          <input
            id='from-name'
            type='text'
            className='my-2 p-2 rounded-md'
            placeholder='Please enter your name'
            name='from_name'
            value={formValues.from_name}
            onChange={(e) =>
              setFormValues({ ...formValues, from_name: e.target.value })
            }
          />

          <label htmlFor='from_email' className='text-white'>
            Email ID
          </label>
          <input
            type='email'
            className='my-2 p-2 rounded-md'
            placeholder='Please enter your email'
            name='from_email'
            id='from_email'
            value={formValues.from_email}
            onChange={(e) =>
              setFormValues({ ...formValues, from_email: e.target.value })
            }
          />

          <label htmlFor='query' className='text-white'>
            Leave us a message!
          </label>
          <textarea
            value={formValues.message}
            className='my-2 p-2 rounded-md'
            name='message'
            id='query'
            cols='30'
            rows='7'
            placeholder='Please leave a message here'
            onChange={(e) =>
              setFormValues({ ...formValues, message: e.target.value })
            }
          ></textarea>
          <button className='bg-primary text-white font-semibold text-xl mt-3 text-center rounded-md p-2'>
            {loading ? 'Loading...' : 'Send'}
          </button>
        </form>
      </div>
    </>
  );
};

export default HelpMenu;
