import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import './MobileMenu.css';
import {
  deleteCurrentSession,
  getCategories,
  getCurrentUser,
} from '../../actions';
import { store } from '../../app/persist';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useSelector } from 'react-redux';
import { currentCartState } from '../../app/cartSlice';
import { currentWishlistState } from '../../app/wishlistSlice';
import { useNavigate } from 'react-router-dom';

export default function MobileNav({ menu, setMenu, categories }) {
  const [isOpen, setIsOpen] = useState(false);

  const cartProducts = useSelector(currentCartState);
  const wishlistProducts = useSelector(currentWishlistState);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const [user, setUser] = useState(false);
  const loadCurrentUser = async () => {
    const currentUser = await getCurrentUser();
    if (currentUser) {
      setUser(true);
    }
    return currentUser;
  };
  const logoutCurrentUser = async () => {
    await deleteCurrentSession().then(() => {
      setUser(false);
      navigate('/login');
    });
    console.log(loadCurrentUser());
  };

  useEffect(() => {
    loadCurrentUser();
  }, []);

  useEffect(() => {
    // Add event listeners to disable scrolling when the menu is open
    if (menu) {
      document.body.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
    }

    // Clean up event listeners on component unmount
    return () => {
      document.body.classList.remove('disable-scroll');
    };
  }, [menu]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (searchTerm.length > 0) {
      navigate('/search', {
        state: {
          searchTerm: searchTerm,
        },
      });
      setSearchTerm('');
      setMenu(false);
    }
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={`lg:hidden relative z-[1004] w-8`}>
      {/* Menu Screen */}

      <div
        onClick={handleClick}
        className={
          isOpen
            ? `top-0 right-0 w-[100vw] bg-black/40 p-10  fixed h-screen z-30 `
            : 'hidden'
        }
      ></div>
      <div
        className={
          isOpen
            ? `top-0 right-0 z-[1002] w-[80vw] overflow-auto bg-white px-3 text-black fixed h-screen   ease-in-out duration-300 translate-x-0 `
            : `top-0 right-0 z-[1002] w-[80vw] overflow-auto bg-white px-3 text-black fixed h-screen   ease-in-out duration-300 translate-x-full`
        }
      >
        <h2 className='mt-20 text-lg font-semibold text-gray-800'>
          <ul className='flex flex-col w-full mb-10'>
            <li className='border-b border-gray-200'>
              <a className='flex select-none items-center p-2' href='/'>
                <i className='bi bi-house-door mr-3 flex text-xl text-primary-color'></i>
                <span className='font-semibold'>Home</span>
              </a>
            </li>
            <li className='border-b border-gray-200 mt-2'>
              <div className='metismenu-btn has-arrow flex cursor-pointer select-none items-center p-2'>
                <i className='bi bi-person mr-3 flex text-xl text-primary-color'></i>
                <span className='font-semibold'>My Account</span>
              </div>
              {!user ? (
                <div className='metismenu-content mm-collapse'>
                  <a className='ml-10 block p-1 font-normal' href='/login'>
                    Login
                  </a>
                </div>
              ) : (
                <div className='metismenu-content mm-collapse mb-2'>
                  <a className='ml-10 block p-1 font-normal' href='/profile'>
                    Profile
                  </a>
                  <button
                    className='ml-10  p-1 flex w-full font-normal'
                    onClick={logoutCurrentUser}
                  >
                    Logout
                  </button>
                </div>
              )}
            </li>
            <li className='border-b border-gray-200 mt-2'>
              <div className='cursor-pointer p-2'>
                <div className='flex cursor-pointer items-center'>
                  <a href='/wishlist' className='flex w-full space-x-2 items-center'>
                    <FavoriteBorderOutlinedIcon />
                    <span className='font-semibold w-full'>Wishlist</span>
                  </a>
                  <span className='badge ml-auto h-[22px] min-w-[22px] px-[6px]'>
                    {wishlistProducts.length}
                  </span>
                </div>
              </div>
            </li>
            <li className='border-b border-gray-200 mb-2'>
              <div className='cursor-pointer p-2'>
                <div className='flex cursor-pointer items-center'>
                  <a href='/cart' className='flex w-full space-x-2 items-center'>
                    <ShoppingCartOutlinedIcon />
                    <span className='font-semibold w-full'>Cart</span>
                  </a>
                  <span className='badge ml-auto h-[22px] min-w-[22px] px-[6px]'>
                    {cartProducts.length}
                  </span>
                </div>
              </div>
            </li>
            <li className='border-b border-gray-200 px-3 mt-2'>
              <span className='font-semibold'>Categories</span>
            </li>
            {categories?.map((cat, idx) => (
              <li key={idx} className='border-b border-gray-200 px-9'>
                <a
                  className='flex select-none items-center p-2'
                  href={`/group/${cat.$id}`}
                >
                  <span className='font-normal'>{cat.name}</span>
                </a>
              </li>
            ))}
            <li className='border-b border-gray-200 mt-4'>
              <a className='flex select-none items-center p-2' href='faqs.html'>
                <i className='bi bi-question-circle mr-3 flex text-xl text-primary-color'></i>
                <span className='font-semibold'>FAQ's</span>
              </a>
            </li>
            <li className='border-b border-gray-200'>
              <a className='flex select-none items-center p-2' href='/contact'>
                <i className='bi bi-telephone mr-3 flex text-xl text-primary-color'></i>
                <span className='font-semibold'>Contact Us</span>
              </a>
            </li>
          </ul>
        </h2>
      </div>

      <button
        onClick={handleClick}
        className='flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[1004] flex-col justify-center items-center '
      >
        <span
          className={
            isOpen
              ? ` block transition-all duration-300 ease-out 
                    h-0.5 w-6 rounded-sm rotate-45 translate-y-1 bg-black`
              : 'bg-white block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm -translate-y-0.5'
          }
        ></span>
        <span
          className={
            isOpen
              ? `bg-white block transition-all duration-300 ease-out 
                    h-0.5 w-6 rounded-sm my-0.5 opacity-0`
              : 'bg-white block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm my-0.5 opacity-100'
          }
        ></span>
        <span
          className={
            isOpen
              ? ` block transition-all duration-300 ease-out 
                    h-0.5 w-6 rounded-sm -rotate-45 -translate-y-1 bg-black`
              : 'bg-white block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm translate-y-0.5'
          }
        ></span>
      </button>
    </div>
  );
}
