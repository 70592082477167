import React, { useState } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useNavigate } from 'react-router-dom';

function MobileSearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (searchTerm.length > 0) {
      navigate('/search', {
        state: {
          searchTerm: searchTerm,
        },
      });
      setSearchTerm('');
    }
  };

  return (
    <div className='px-[1.5rem]  py-4 sticky top-0 z-30 lg:hidden bg-[#0b0d17]'>
      <form onSubmit={(e) => handleSubmit(e)} className='search'>
        <div className='flex h-[40px] overflow-hidden rounded-md bg-white'>
          <button className='px-3 text-black' type='submit'>
            <SearchOutlinedIcon />
          </button>
          <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className='w-full text-sm border-none py-[5px] bg-white pl-5 text-black focus:border-none focus:outline-none appearance-none search-bar placeholder-black'
            type='search'
            placeholder='Search...'
          />
        </div>
      </form>
    </div>
  );
}

export default MobileSearch;
