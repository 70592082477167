// import "./Dashboard.css";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PaidIcon from '@mui/icons-material/Paid';
import HttpsIcon from '@mui/icons-material/Https';
import HelpIcon from '@mui/icons-material/Help';
import Products from './Products/Products';
import Categories from './Categories/Categories';
import Navbar from '../Navbar/Navbar.js';
import Footer from '../Footer/Footer';
import { useEffect, useState } from 'react';
import CarouselImages from './CarouselImages';
import ContactQuery from '../ContactQuery/ContactQuery';
import Loading from '../../utils/Loading';
import { Helmet } from 'react-helmet';

const Dashboard = ({ products, categories }) => {
  const [loading, setLoading] = useState(true);

  return (
    <div className='flex flex-col w-full'>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='theme-color' content='#FFFFFF' />
        <meta
          name='description'
          content='Discover the latest tech treasures at TechSouqDubai, your one-stop ecommerce destination in the UAE. Shop a wide range of products, from high-performance monitors and cutting-edge keyboards to smart sensors and top-tier gaming gear. Elevate your tech game with us!'
        />

        <link rel='manifest' href='%PUBLIC_URL%/manifest.json' />

        <title>TechSouqDubai</title>

        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
        <link
          href='https://fonts.googleapis.com/css2?family=Audiowide&family=Poppins:ital,wght@0,500;1,600&display=swap'
          rel='stylesheet'
        />
      </Helmet>
      <div className={loading ? 'block' : 'hidden'}>
        <Loading />
      </div>
      <div className={loading ? 'hidden' : 'block'}>
        <Navbar />

        <ContactQuery />
        <div className='mx-auto  mb-5  '>
          <div className='grid grid-cols-12 min-h-[200px] gap-4 w-full'>
            <div className='col-span-12  shadow-lg'>
              {/* Carousel */}
              <CarouselImages setLoad={setLoading} load={loading} />
            </div>
            {/* <div className="group relative col-span-12 lg:col-span-4 overflow-hidden rounded-lg md:block shadow-lg">
            <div className="overlay-gradient absolute z-[1] h-full w-full"></div>
            <img
              className="transition-all duration-300 h-full w-full object-cover hover:transform group-hover:scale-110"
              src="armedpc.jpg"
              alt="banner-img"
            />
            <div className="absolute top-0 left-0 flex h-full w-full items-center">
              <div className="z-[2] p-6">
                <h3 className="text-lg font-bold uppercase text-white drop-shadow-xl">
                  Armed pc gamer
                </h3>
                <p className="my-5 text-white drop-shadow-md">
                  Choose your pc and play!
                </p>
                <a
                  className="btn-effect inline-block rounded-lg bg-blue-500 py-2 px-3 text-white"
                  href="#"
                >
                  <span>Shop now</span>
                </a>
              </div>
            </div>
          </div> */}
          </div>
        </div>

        <div className="my-20">
              {categories.map((cat, idx) => {
                let relevantProducts = [];
                for (let prod of products) {
                  if (prod.category.$id === cat.$id) {
                    relevantProducts.push(prod);
                  }
                }
      
                if (relevantProducts.length)
                  return <Products title={cat.name} products={relevantProducts} />;
              })}
         </div>

        {/* <Products title="Categories" products={categories} /> */}

        <Categories />

        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
